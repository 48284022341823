import Vue from 'vue';
import App from './App';
import { router } from './router';
import Vant from 'vant';
import store from './store';
import 'vant/lib/index.css';
import './assets/css/style.css'
import './assets/css/baseStyle.css'
import './assets/css/fontsize.css'

import 'amfe-flexible/index.js'
import 'babel-polyfill'
import wx from 'weixin-js-sdk'
import vueEsign from 'vue-esign'
import { Toast } from 'vant';
Toast.setDefaultOptions({ duration: 2000 })
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(vueEsign)
Vue.prototype.wx = wx
import moment from "moment";
Vue.prototype.$moment = moment;
Vue.use(Vant);
//视频播放
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

Vue.use(VideoPlayer)
//授权分享全局

//瀑布流
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)
Vue.config.ignoredElements = ['wx-open-launch-weapp']
router.afterEach((to, from, next) => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
  window.scrollTo(0, 0);
})
new Vue({
  router,
  store: store,
  el: '#app',
  render: h => h(App)
});
