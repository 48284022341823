import Vue from "vue";
import Router from "vue-router";
import { Toast } from "vant";
// import utils from './components/utils'
import store from "./store";
Vue.use(Router);
var usename = "";
// console.log(localStorage.getItem("userInfo"))
if (localStorage.getItem("userInfo") == "undefined") {
  usename = "";
} else if (localStorage.getItem("userInfo") == null) {
  // use = {
  //   mainName: usename
  // }
  usename = "";
} else {
  var user = JSON.parse(localStorage.getItem("userInfo"));
  if (
    user.mainName == "驿车驹宏开店" ||
    user.mainName == "易车驹门店测试" ||
    user.mainName == "驿车驹·宏开店"
  ) {
    usename = "驿车驹";
  } else if (user.mainName == "亿迈程—胜创" || user.gid == "491") {
    usename = "亿迈程—胜创";
  } else {
    usename = user.mainName;
  }
}
// eslint-disable-next-line no-sparse-arrays
const routes = [
  {
    path: "*",
    redirect: "/user",
  },
  {
    path: "/auths/:code?/:state?",
    name: "auths",
    component: () => import("./view/user/auths"),
    meta: {
      title: "",
    },
  },
  {
    path: "/user",
    name: "user",
    component: () => import("./view/user"),
    meta: {
      title: "",
      isBack: false, //用于判断上一个页面是哪个
      requireAuth4: true, // 判断是否添加修理厂
    },
  },
  {
    path: "/schedule",
    name: "schedule",
    component: () => import("./view/user/schedule"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/Store_list",
    name: "Store_list",
    component: () => import("./view/user/Store_list"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/isok",
    name: "isok",
    component: () => import("./view/user/isok"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      requireAuth5: true,
    },
  },
  {
    path: "/list",
    name: "list",
    component: () => import("./view/user/list"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      requireAuth2: true, // 添加该字段，表示进入这个需要车型
    },
  },
  {
    path: "/specification",
    name: "specification",
    component: () => import("./view/user/specification"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      requireAuth2: true, // 添加该字段，表示进入这个需要车型
    },
  },
  {
    path: "/list_miao",
    name: "list_miao",
    component: () => import("./view/user/list_miao"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      requireAuth2: true, // 添加该字段，表示进入这个需要车型
    },
  },
  {
    path: "/list_miao_details",
    name: "list_miao_details",
    component: () => import("./view/user/list_miao_details"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个需要车型
    },
  },
  {
    path: "/list_seckill",
    name: "list_seckill",
    component: () => import("./view/user/list_seckill"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个需要车型
    },
  },

  {
    path: "/common",
    name: "common",
    component: () => import("./view/user/common"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/commonadd",
    name: "commonadd",
    component: () => import("./view/user/commonadd"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/list_details",
    name: "list_details",
    component: () => import("./view/user/list_details"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/list_details_xiche",
    name: "list_details_xiche",
    component: () => import("./view/user/list_details_xiche"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/supermarket",
    name: "supermarket",
    component: () => import("./view/user/supermarket"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/supermarket_details",
    name: "supermarket_details",
    component: () => import("./view/user/supermarket/supermarket_details"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/start_business",
    name: "start_business",
    component: () => import("./view/user/start_business"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/jiuimg",
    name: "jiuimg",
    component: () => import("./view/user/jiuimg"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/maintain",
    name: "maintain",
    component: () => import("./view/user/maintain"),
    meta: {
      keepAlive: true,
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      requireAuth2: true, // 添加该字段，表示进入这个路由是需要登车型
      // requireAuth3: true,  // 添加该字段，表示进入这个路由是需要车架号
    },
  },
  {
    path: "/model",
    name: "model",
    component: () => import("./view/user/model"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/authentication",
    name: "authentication",
    component: () => import("./view/user/authentication"),
    meta: {
      keepAlive: true,
      title: usename,
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/model_list",
    name: "model_list",
    component: () => import("./view/user/model_list"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    // path: '/maintain/:gid/:oid',
    path: "/change_product",
    name: "change_product",
    component: () => import("./view/user/change_product"),
    meta: {
      title: usename,
    },
  },
  {
    // path: '/maintain/:gid/:oid',
    path: "/change_details",
    name: "change_details",
    component: () => import("./view/user/change_details"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/washcar",
    name: "washcar",
    component: () => import("./view/user/washcar"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/place_order",
    name: "place_order",
    component: () => import("./view/user/place_order"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/gift_bag",
    name: "gift_bag",
    component: () => import("./view/user/gift_bag"),
    meta: {
      title: usename,
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/car_award",
    name: "car_award",
    component: () => import("./view/user/car_award"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/gift_activity",
    name: "gift_activity",
    component: () => import("./view/user/gift_activity"),
    meta: {
      title: usename,
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/gift_newest",
    name: "gift_newest",
    component: () => import("./view/user/gift_newest"),
    meta: {
      title: usename,
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/Automatic_Carimg",
    name: "Automatic_Carimg",
    component: () => import("./view/user/Automatic_Carimg"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import("./view/user/partner"),
    meta: {
      title: usename,
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/signature",
    name: "signature",
    component: () => import("./view/user/signature"),
    meta: {
      title: usename,
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/repair",
    name: "repair",
    component: () => import("./view/repair"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/repair_info/:id",
    name: "repair_info",
    component: () => import("./view/repair/info"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/app_h5",
    name: "app_h5",
    component: () => import("./view/app_h5"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/paint_mixing",
    name: "paint_mixing",
    component: () => import("./view/app_h5/paint_mixing"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/Insurance_member",
    name: "Insurance_member",
    component: () => import("./view/app_h5/Insurance_member"),
    meta: {
      title: "驿车驹·宏开店",
    },
  },
  {
    path: "/flow",
    name: "flow",
    component: () => import("./view/app_h5/flow"),
    meta: {
      title: "驿车驹",
    },
  },
  {
    path: "/ycjpay",
    name: "ycjpay",
    component: () => import("./view/app_h5/ycjpay"),
    meta: {
      title: "支付中心",
    },
  },
  {
    path: "/paint",
    name: "paint",
    component: () => import("./view/app_h5/paint"),
    meta: {
      title: "驿车驹",
      keepAlive: true,
      //钣喷同行列表
    },
  },
  {
    path: "/paint_list",
    name: "paint_list",
    component: () => import("./view/app_h5/paint/paint_list"),
    meta: {
      title: "驿车驹",
      //钣喷同行详情数据
    },
  },
  {
    path: "/paint_yewuyuan",
    name: "paint_yewuyuan",
    component: () => import("./view/app_h5/paint/paint_yewuyuan"),
    meta: {
      title: "驿车驹",
      //钣喷同行详情数据
    },
  },
  {
    path: "/paint_list_details",
    name: "paint_list_details",
    component: () => import("./view/app_h5/paint/paint_list_details"),
    meta: {
      title: "驿车驹",
      //钣喷同行详情数据
    },
  },
  {
    path: "/bill_list",
    name: "bill_list",
    component: () => import("./view/app_h5/paint/bill_list"),
    meta: {
      title: "驿车驹",
      keepAlive: true,
      //钣喷同行详情数据
    },
  },
  {
    path: "/bill_details",
    name: "bill_details",
    component: () => import("./view/app_h5/paint/bill_details"),
    meta: {
      title: "驿车驹",
      //钣喷同行详情数据
    },
  },
  {
    path: "/bill_pay",
    name: "bill_pay",
    component: () => import("./view/app_h5/paint/bill_pay"),
    meta: {
      title: "驿车驹",
      //钣喷同行详情数据
    },
  },
  {
    path: "/bill_ewm",
    name: "bill_ewm",
    component: () => import("./view/app_h5/paint/bill_ewm"),
    meta: {
      title: "驿车驹",
      //钣喷同行详情数据
    },
  },
  {
    path: "/bx_list",
    name: "bx_list",
    component: () => import("./view/app_h5/paint/bx_list"),
    meta: {
      title: "驿车驹",
      //钣喷同行保险详情数据
    },
  },
  {
    path: "/offer",
    name: "offer",
    component: () => import("./view/app_h5/offer"),
    meta: {
      title: "驿车驹",
    },
  },
  {
    path: "/offer_quotation",
    name: "offer_quotation",
    component: () => import("./view/app_h5/offer/offer_quotation"),
    meta: {
      title: "驿车驹",
    },
  },
  {
    path: "/offer_list",
    name: "offer_list",
    component: () => import("./view/app_h5/offer/offer_list"),
    meta: {
      title: "驿车驹",
    },
  },
  {
    path: "/offer_pj",
    name: "offer_pj",
    component: () => import("./view/app_h5/offer/offer_pj"),
    meta: {
      title: "驿车驹",
    },
  },
  {
    path: "/offer_details",
    name: "offer_details",
    component: () => import("./view/app_h5/offer/offer_details"),
    meta: {
      title: "驿车驹",
    },
  },
  ,
  {
    path: "/sharing",
    name: "sharing",
    component: () => import("./view/app_h5/sharing"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/sharing_list",
    name: "sharing_list",
    component: () => import("./view/app_h5/sharing/sharing_list"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/enquiry",
    name: "enquiry",
    component: () => import("./view/app_h5/enquiry"),
    meta: {
      keepAlive: true,
      title: usename,
    },
  },
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("./view/app_h5/enquiry/purchase"),
    meta: {
      title: usename,
      keepAlive: true, // 需要缓存页面
    },
  },
  {
    path: "/purchase_app",
    name: "purchase_app",
    component: () => import("./view/app_h5/enquiry/purchase_app"),
    meta: {
      title: usename,
      keepAlive: true, // 需要缓存页面
    },
  },
  {
    //采购清单
    path: "/purchase_cg",
    name: "purchase_cg",
    component: () => import("./view/app_h5/enquiry/purchase_cg"),
    meta: {
      title: usename,
      keepAlive: true, // 需要缓存页面
    },
  },
  {
    path: "/index_yg",
    name: "index_yg",
    component: () => import("./view/app_h5/enquiry/index_yg"),
    meta: {
      title: usename,
      keepAlive: true, // 需要缓存页面
    },
  },
  {
    path: "/showimg",
    name: "showimg",
    component: () => import("./view/app_h5/enquiry/showimg"),
    meta: {
      title: usename,
      // keepAlive: true // 需要缓存页面
    },
  },
  {
    path: "/meal",
    name: "meal",
    component: () => import("./view/meal"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/meal_info/:id",
    name: "meal_info",
    component: () => import("./view/meal/info"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/select",
    name: "select",
    component: () => import("./view/meal/select"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/mine",
    name: "mine",
    component: () => import("./view/mine"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/order",
    name: "order",
    component: () => import("./view/mine/order"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/order_details",
    name: "order_details",
    component: () => import("./view/mine/order_details"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/consume",
    name: "consume",
    component: () => import("./view/mine/consume"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/consume_details",
    name: "consume_details",
    component: () => import("./view/mine/consume_details"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/member",
    name: "member",
    component: () => import("./view/mine/member"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/member_details",
    name: "member_details",
    component: () => import("./view/mine/member_details"),
    meta: {
      title: usename,
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/huiyuan_details",
    name: "huiyuan_details",
    component: () => import("./view/mine/huiyuan_details"),
    meta: {
      title: usename,
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/package",
    name: "package",
    component: () => import("./view/mine/package"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/value_card",
    name: "value_card",
    component: () => import("./view/mine/value_card"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/water_subsidiary",
    name: "water_subsidiary",
    component: () => import("./view/mine/water_subsidiary"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/water_list",
    name: "water_list",
    component: () => import("./view/mine/water_list"),
    meta: {
      title: usename,
    },
  },

  {
    path: "/top_up",
    name: "top_up",
    component: () => import("./view/mine/top_up"),
    meta: {
      title: usename,
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/ka_pay",
    name: "ka_pay",
    component: () => import("./view/mine/ka_pay"),
    meta: {
      title: usename,
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/make_list",
    name: "make_list",
    component: () => import("./view/mine/make_list"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/make_list_details",
    name: "make_list_details",
    component: () => import("./view/mine/make_list_details"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/make_xiangqing",
    name: "make_xiangqing",
    component: () => import("./view/mine/make_xiangqing"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/order_yuyue",
    name: "order_yuyue",
    component: () => import("./view/mine/order_yuyue"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/youhui_list",
    name: "youhui_list",
    component: () => import("./view/mine/youhui_list"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/youhui_linqu",
    name: "youhui_linqu",
    component: () => import("./view/mine/youhui_linqu"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/Lucky_draw",
    name: "Lucky_draw",
    component: () => import("./view/mine/Lucky_draw"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      requireAuth2: true, // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/Lucky_mine",
    name: "Lucky_mine",
    component: () => import("./view/mine/Lucky_mine"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/Lucky_ma",
    name: "Lucky_ma",
    component: () => import("./view/mine/Lucky_ma"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/tuoke_coupon",
    name: "tuoke_coupon",
    component: () => import("./view/mine/tuoke_coupon"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/insurance",
    name: "insurance",
    component: () => import("./view/mine/insurance"),
    meta: {
      title: usename,
    },
  },
  ,
  {
    path: "/tuoke_coupon_details",
    name: "tuoke_coupon_details",
    component: () => import("./view/mine/tuoke_coupon_details"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/tuoke_shopping",
    name: "tuoke_shopping",
    component: () => import("./view/mine/tuoke_shopping"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/personal",
    name: "personal",
    component: () => import("./view/mine/personal"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/modification",
    name: "modification",
    component: () => import("./view/mine/modification"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/My_performance",
    name: "My_performance",
    component: () => import("./view/My_performance"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/My_withdraw",
    name: "My_withdraw",
    component: () => import("./view/My_performance/My_withdraw"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/My_record",
    name: "My_record",
    component: () => import("./view/My_performance/My_record"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/My_list",
    name: "My_list",
    component: () => import("./view/My_performance/My_list"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/My_listb",
    name: "My_listb",
    component: () => import("./view/My_performance/My_listb"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/My_secondTow",
    name: "My_secondTow",
    component: () => import("./view/My_performance/My_secondTow"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/My_team",
    name: "My_team",
    component: () => import("./view/My_performance/My_team"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/spray_lacquer",
    name: "spray_lacquer",
    component: () => import("./view/spray_lacquer"),
    meta: {
      keepAlive: true,
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/spray_order",
    name: "spray_order",
    component: () => import("./view/spray_lacquer/spray_order"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      requireAuth2: true, // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/car_tasted",
    name: "car_tasted",
    component: () => import("./view/car_tasted"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      // requireAuth2: true,  // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/tasted_details",
    name: "tasted_details",
    component: () => import("./view/car_tasted/tasted_details"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      requireAuth2: true, // 添加该字段，表示进入这个路由是需要车型
    },
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("./view/shop"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/yuangong_list",
    name: "yuangong_list",
    component: () => import("./view/shop/yuangong_list"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/mendian_img",
    name: "mendian_img",
    component: () => import("./view/shop/mendian_img"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/comment",
    name: "comment",
    component: () => import("./view/shop/comment"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/exclusive",
    name: "exclusive",
    component: () => import("./view/exclusive"),
    meta: {
      title: usename,
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/codema",
    name: "codema",
    component: () => import("./view/exclusive/codema"),
    meta: {
      title: "分享码",
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/guarantee",
    name: "guarantee",
    component: () => import("./view/guarantee"),
    meta: {
      title: usename,

      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/Add_car",
    name: "Add_car",
    component: () => import("./view/guarantee/Add_car"),
    meta: {
      title: usename,
      keepAlive: true,
    },
  },
  {
    path: "/hint",
    name: "hint",
    component: () => import("./view/guarantee/hint"),
    meta: {
      title: usename,
      keepAlive: true,
    },
  },
  {
    path: "/Userowner",
    name: "Userowner",
    component: () => import("./view/guarantee/Userowner"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/examining_report",
    name: "examining_report",
    component: () => import("./view/guarantee/examining_report"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/contract",
    name: "contract",
    component: () => import("./view/guarantee/contract"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/inspect_car",
    name: "inspect_car",
    component: () => import("./view/inspect_car"),
    meta: {
      title: usename,
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/Inspection_car",
    name: "Inspection_car",
    component: () => import("./view/inspect_car/Inspection_car"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/Vehicle_car",
    name: "Vehicle_car",
    component: () => import("./view/inspect_car/Vehicle_car"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/my_order",
    name: "my_order",
    component: () => import("./view/inspect_car/my_order"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/my_order_details",
    name: "my_order_details",
    component: () => import("./view/inspect_car/my_order_details"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/exemption", //免检领标
    name: "exemption",
    component: () => import("./view/inspect_car/exemption"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/inquire",
    name: "inquire",
    component: () => import("./view/inquire"),
    meta: {
      title: usename,
      // requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
  },

  {
    path: "/tuoke",
    name: "tuoke",
    component: () => import("./view/tuoke"),
    meta: {
      title: usename,
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
      requireAuthphone: true, // 添加该字段，表示进入这个路由是需要登录的
    },
  },
  {
    path: "/tuoke_apply",
    name: "tuoke_apply",
    component: () => import("./view/tuoke/tuoke_apply"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/indextwo",
    name: "indextwo",
    component: () => import("./view/tuoke/indextwo"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_recommend",
    name: "tuoke_recommend",
    component: () => import("./view/tuoke/tuoke_recommend"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_recommendTwo",
    name: "tuoke_recommendTwo",
    component: () => import("./view/tuoke/tuoke_recommendTwo"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_tichengSZ",
    name: "tuoke_tichengSZ",
    component: () => import("./view/tuoke/tuoke_tichengSZ"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_record",
    name: "tuoke_record",
    component: () => import("./view/tuoke/tuoke_record"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_result/:id/:gid/:name",
    name: "tuoke_result",
    component: () => import("./view/tuoke/tuoke_result"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_list",
    name: "tuoke_list",
    component: () => import("./view/tuoke/tuoke_list"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_income/:gid/:garageName",
    name: "tuoke_income",
    component: () => import("./view/tuoke/tuoke_income"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_detail/:list",
    name: "tuoke_detail",
    component: () => import("./view/tuoke/tuoke_detail"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_initiation",
    name: "tuoke_initiation",
    component: () => import("./view/tuoke/tuoke_initiation"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/tuoke_ma/:garageName/:gid/:customerId",
    name: "tuoke_ma",
    component: () => import("./view/tuoke/tuoke_ma"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/qrcode",
    name: "qrcode",
    component: () => import("./view/qrcode"),
    meta: {
      title: "驿车驹",
    },
  },
  {
    path: "/userpay",
    name: "userpay",
    component: () => import("./view/qrcode/userpay"),
    meta: {
      title: "驿车驹",
    },
  },
  {
    path: "/qian",
    name: "qian",
    component: () => import("./view/qian"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/authorisedtuoke",
    name: "authorisedtuoke",
    component: () => import("./view/authorisedtuoke"),
    meta: {
      title: usename,
    },
  },
  {
    path: "/invitation",
    name: "invitation",
    component: () => import("./view/invitation"),
    meta: {
      title: "宽心雅苑",
    },
  },
  {
    path: "/examine",
    name: "examine",
    component: () => import("./view/app_h5/examine/index"),
    meta: {
      title: "收入审核(出纳专用)",
      keepAlive: true,
    },
  },
  {
    path: "/examinedetails",
    name: "examinedetails",
    component: () => import("./view/app_h5/examine/details"),
    meta: {
      title: "收入审核(出纳专用)详情",
      keepAlive: true,
    },
  },
  {
    path: "/pending",
    name: "pending",
    component: () => import("./view/app_h5/pending/index"),
    meta: {
      title: "待定损多单收款",
      keepAlive: true,
    },
  },
  {
    path: "/pendingadd",
    name: "pendingadd",
    component: () => import("./view/app_h5/pending/add"),
    meta: {
      title: "待定损多单收款-新增",
      keepAlive: true,
    },
  },,
  {
    path: "/pendingedit",
    name: "pendingedit",
    component: () => import("./view/app_h5/pending/edit"),
    meta: {
      title: "待定损多单收款-修改",
      keepAlive: true,
    },
  },
  {
    path: "/pendingdetail",
    name: "pendingdetail",
    component: () => import("./view/app_h5/pending/details"),
    meta: {
      title: "待定损多单收款-详情",
      keepAlive: true,
    },
  },
];

// add route path
routes.forEach((route) => {
  route.path = route.path || "/" + (route.name || "");
});

const RouterConfig = {
  mode: "history",
  routes: routes,
};

const router = new Router(RouterConfig);

router.beforeEach((to, from, next) => {
  let userAgent = navigator.userAgent;
  if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
    sessionStorage.setItem("originUrl", location.href); // 用于ios分享
  }
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  // let gid = utils.getUrlKey('gid')
  // console.log(gid)
  if (
    (from.name === "user" || from.name === "youhui_list") &&
    to.name === "maintain"
  ) {
    to.meta.isBack = true;
  }
  if (from.name === "guarantee" && to.name === "Add_car") {
    to.meta.isBack = true;
  }
  const token = localStorage.getItem("Authorization");
  // console.log(store.state.appd)
  // localStorage.setItem('beforeUrl', from.name)

  if (to.meta.requireAuth) {
    // 判断该路由是否需要登录权限
    if (localStorage.getItem("Authorization")) {
      //判断本地是否存在access_token
      next();
    } else {
      const appid = sessionStorage.getItem("appid");

      // 正式
      const redirect_uri = encodeURIComponent(
        "https://shopping.car-posthouse.cn/auths"
      );
      // window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;

      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      // 测试
      // const redirect_uri = encodeURIComponent(
      //   "http://shoppingtest.car-posthouse.cn/auths"
      // );
      // window.location.href = `http://marketing.car-posthouse.cn/get-weixin-code.html?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    }
  }
  // console.log(localStorage.getItem("userInfo"))
  if (localStorage.getItem("userInfo") != "undefined") {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (to.meta.requireAuthphone) {
      // 判断该路由是否需要登录权限
      // console.log(userInfo.phone)
      if (userInfo.phone == null || userInfo.phone == "null") {
        next({
          path: "/modification",
          query: {
            redirect: to.fullPath,
            cid: 1,
            titel: "请先保存手机号码",
          }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
        });
      } else {
        // console.log('拦截')
        next();
      }
    }
  }

  if (to.meta.requireAuth2) {
    // 判断该路由是否需要登录权限
    var chexin = JSON.parse(sessionStorage.getItem("chexin"));
    console.log(chexin);
    if (chexin) {
      if (chexin.carBrand == null || chexin.carBrand == "null") {
        // console.log('不拦截')
        next({
          path: "/model",
          query: {
            redirect: to.fullPath,
            isok: "true",
            titel: "请先选择车型",
          }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
        });
        return;
      } else {
        // console.log('拦截')
        next();
      }
    } else {
      next({
        path: "/model",
        query: {
          redirect: to.fullPath,
          isok: "true",
          titel: "请先选择车型",
        }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
      return;
    }
  }
  if (to.meta.requireAuth3) {
    // 判断该路由是否需要车架号
    // eslint-disable-next-line no-redeclare
    var chexin = JSON.parse(sessionStorage.getItem("chexin"));
    // console.log(chexin.carVin)
    if (
      chexin.carVin == null ||
      chexin.carVin == "" ||
      chexin.carVin == undefined
    ) {
      //判断本地是否存在access_token

      Toast("请先设置车架号！");
      setTimeout(() => {
        next({
          path: "/model",
          query: {
            redirect: to.fullPath,
            isok: "true",
            titel: "请设置车架号",
          }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
        });
      }, 900);
    } else {
      next();
    }
  }

  if (to.meta.requireAuth4) {
    // 判断该路由修理厂
    const scisok = sessionStorage.getItem("scisok");
    console.log(scisok);
    if (scisok == "false") {
      console.log("走到这拦截跳转");
      next({
        path: "isok",
      });
    }
  }
  if (to.meta.requireAuth5) {
    // 判断该路由修理厂
    const scisok = sessionStorage.getItem("scisok");
    if (scisok == "true") {
      next({
        path: "user",
      });
    } else {
      next();
    }
  }
  /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
  if (to.fullPath == "/auths") {
    if (localStorage.getItem("Authorization")) {
      next({
        path: from.fullPath,
      });
    } else {
      next();
    }
  }
  console.log('to.name===',to.name);
  console.log('from.name===',from.name);
  if (token) {
    if (
      (from.name === "user" ||
        from.name === "Store_list" ||
        from.name === "start_business" ||
        from.name === "enquiry" ||
        from.name === "purchase") &&
      to.name === "auths"
    ) {
      console.log("关闭");

      parent.WeixinJSBridge.call("closeWindow");
    }
  }
  next();
});

export { router };
